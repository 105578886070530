import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/Layout';
import Person from '../components/Person/Person';
import euan_profile_img from '../images/people/euan_profile.jpg';
import matt_profile_img from '../images/people/matt_profile.jpg';
import clive_profile_img from '../images/people/clive_profile.jpg';
const About = () => {
  const seoProps = {
    title: 'About Us',
    description:
      'Filament is a data science notebook that helps teams explore, visualise, and extract insights from data.',
  };

  return (
    <Layout seoProps={seoProps}>
      <Container>
        <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            <h1 className="h2 mb-4 text-center">About Filament</h1>
            <p className="text-center">
              Filament was founded on the belief that making data and analysis
              more accessible, collaborative, and useful will transform the way
              work is conducted, how knowledge is shared, and how complex global
              problems are solved.
            </p>
            <p className="text-center">
              We passionately believe in open knowledge, open science, and open
              source. That’s why you will always own your data, we won’t lock
              you into our platform, and we will always offer a free tier of
              Filament.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center mt-5">Founding Team</h2>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col sm={12} md={4}>
            <Person
              name="Euan Wielewski, CEO"
              bio="Euan likes science fiction, hardcore punk, and single malt whisky. He has a PhD in Engineering Science from the University of Oxford and was formerly an Assistant Professor at the University of Glasgow. He has over 15 years experience in data analysis and machine learning, and is responsible for setting the direction of Filament."
              img={euan_profile_img}
            />
          </Col>
          <Col sm={12} md={4}>
            <Person
              name="Matt Davies, CTO"
              bio="Matt likes anything fast and adrenaline fuelled, whether it’s motorbikes, mountain bikes, or startups. He started his first business whilst still at university and has led several technical teams across multiple companies. He has over 15 years experience building web applications, and is the technical brains behind Filament."
              img={matt_profile_img}
            />
          </Col>
          <Col sm={12} md={4}>
            <Person
              name="Clive Siviour, CSO"
              bio="Clive likes quality beer and curry, and rediscovering Duplo with his son. He is Professor of Engineering Science, and Associate Head of Department, at the University of Oxford. He is an internationally recognised researcher with over 20 years experience in complex data analysis, and more than 100 journal publications."
              img={clive_profile_img}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default About;
