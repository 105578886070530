import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Person.scss';
import genericPerson from '../../images/people/person.jpg';

const Person = (props) => {
  const imgSrc = !!props.img ? props.img : genericPerson;
  return (
    <Container>
      <Row className="mt-4 mb-4">
        <Col className="person">
          <div className="text-center">
            <img
              src={imgSrc}
              alt={props.name}
              className="person__image mb-4"
              width={120}
            />
          </div>
          <h4 className="text-center person__name mb-4">{props.name}</h4>
          <p className="person__bio">{props.bio}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Person;
